var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import sharedStyles from "../../../../../IndeedIntegration/AdEditor/useStyles";
import usestyles from "../useStyles";
import { jobAdPropTypes } from "../../../../../NewUI/Components/Multiposter/types";
import renderHTML from "react-render-html";
const MAX_KEY_SELLING_POINTS = 3;
function Step3({ answers, setAnswers, checkoutUrl, checkNewListingUrl }) {
  const sharedClasses = sharedStyles();
  const classes = usestyles();
  const [newListingCreated, setNewListingCreated] = useState(false);
  const [pollingActive, setPollingActive] = useState(false);
  const intervalRef = useRef(null);
  const handlePayNowClick = () => {
    if (checkoutUrl) {
      window.open(checkoutUrl, "_blank", "noopener,noreferrer");
    }
    startPolling();
  };
  const startPolling = () => {
    setPollingActive(true);
    if (!checkNewListingUrl) return;
    intervalRef.current = setInterval(() => __async(this, null, function* () {
      try {
        const response = yield fetch(checkNewListingUrl);
        const data = yield response.json();
        if (data.success) {
          console.log("Success:", data);
          clearInterval(intervalRef.current);
          setNewListingCreated(true);
          setAnswers(__spreadProps(__spreadValues({}, answers), { advertisement_id: data.listing_id, preview_url: data.url }));
        } else {
          console.log("Error:", data.error);
        }
      } catch (error) {
        console.error("Polling error:", error);
      }
    }), 5e3);
  };
  return /* @__PURE__ */ React.createElement(Box, { className: sharedClasses.stepContainer }, /* @__PURE__ */ React.createElement("h4", null, "Preview Ad"), /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewContainer }, /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewTitle }, `${answers.title} (${answers.workType})`), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: sharedClasses.previewDetails
    },
    `${answers.address} (${answers.workLocation})`
  ), /* @__PURE__ */ React.createElement(
    "div",
    {
      className: sharedClasses.previewDetails
    },
    `$${answers.payRangeLow}-$${answers.payRangeHigh}`
  ), answers.benefits && /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewDetails }, answers.benefits.map((benefit, index) => /* @__PURE__ */ React.createElement("span", { key: index }, benefit, index < answers.benefits.length - 1 && " \u2022 "))), answers.featured && /* @__PURE__ */ React.createElement(Box, { sx: { marginTop: "1rem" } }, /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewDetails }, "Featured Job on Viewjobs"), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewDetails }, answers.jobPreview.summary), Array.from({ length: MAX_KEY_SELLING_POINTS }).map((_, index) => /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewDetails, key: index }, "\u2022 ", answers.jobPreview[`key${index + 1}`]))), /* @__PURE__ */ React.createElement("div", { className: sharedClasses.previewContent }, renderHTML(answers.description)), !answers.preview_url && /* @__PURE__ */ React.createElement("div", { className: classes.previewButtons }, /* @__PURE__ */ React.createElement("div", { onClick: handlePayNowClick, style: { cursor: "pointer" } }, "Pay Now"))), answers.preview_url ? /* @__PURE__ */ React.createElement(Box, { className: sharedClasses.previewTitle }, "Payment successful! You can now view your", " ", /* @__PURE__ */ React.createElement("a", { href: answers.preview_url, target: "_blank", rel: "noopener noreferrer" }, "job listing"), " ", "on ViewJob.") : pollingActive && /* @__PURE__ */ React.createElement(Box, { className: sharedClasses.previewTitle }, "Waiting for payment to be processed..."));
}
Step3.propTypes = jobAdPropTypes;
export default Step3;
